import { useCallback } from 'react'
import axios from 'axios'

const useFinancialClient = () => {
  const getFinancialTickets = useCallback(async (data) => axios.post('/financial/search', data).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const downloadSummaryFinancial = useCallback(async (data) => axios.post('/financial/export/excel', data, {
    responseType: 'arraybuffer',
  }).then((response) => (response?.data ? response : { data: { ...response } })), [])

  const getBilletNotificationOptions = useCallback(async () => axios.get('/financial/BilletNotificationOptions'), [])

  const updateBilletNotification = useCallback(async (data) => axios.post('/financial/UpdateBilletNotification', data).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const dueDateRenewaln = useCallback(async (billingId, newDueDate) => axios.get(`/financial/DueDateRenewal?billingId=${billingId}&newDueDate=${newDueDate}`), [])

  const checkForNewBillet = useCallback(async (billingId) => axios.get(`/financial/CheckForNewBillet?billingId=${billingId}`), [])

  const getAllTickets = useCallback((policyNumber) => axios.get(`financial/billet/download/all?documentNumber=${policyNumber}&base64=true`), [])

  return useCallback(() => ({
    dueDateRenewaln,
    getFinancialTickets,
    downloadSummaryFinancial,
    updateBilletNotification,
    getBilletNotificationOptions,
    getAllTickets,
    checkForNewBillet,
  }), [
    dueDateRenewaln,
    getFinancialTickets,
    downloadSummaryFinancial,
    updateBilletNotification,
    getBilletNotificationOptions,
    getAllTickets,
    checkForNewBillet,
  ])
}

export default useFinancialClient
