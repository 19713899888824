/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, {
    useState,
    useEffect,
    useCallback,
} from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { styled } from '@material-ui/core/styles'

import {
    ModalInfo,
} from '../../../shared/components'
import useSecurity from '../../../security/useSecurity'
import useRegistrationUpdateClient from '../../../clients/RegistrationUpdateClient/useRegistrationUpdateClient'

const linear = 'all 110ms linear'

const useStyles = makeStyles((theme) => ({
    modal: {
        width: 576,
        height: 225,
        borderRadius: 8,
        border: 2,
        gap: 32,
        [theme.breakpoints.down('sm')]: {
            height: 250,
        },
        '& .MuiPaper-root': {
            width: 576,
            height: 302,
            top: 100,
            left: 700,
            borderRadius: 8,
            [theme.breakpoints.up('xl')]: {
                top: 100,
                left: 900,
            },
            [theme.breakpoints.down('md')]: {
                top: 100,
                left: 500,
            },
            [theme.breakpoints.down('sm')]: {
                top: 100,
                left: 0,
                width: 500,
            },
            [theme.breakpoints.down('xs')]: {
                top: 100,
                left: -100,
                width: 374.4,
                height: 250,
            },
        },
    },
    helloUser: {
        fontSize: 18,
        fontWeight: 900,
        textAlign: 'left',
        fontFamily: 'Montserrat',
        color: theme.palette.text.primary,
    },
    textTitle: {
        fontSize: 14,
        fontWeight: 400,
        textAlign: 'left',
        fontFamily: 'Montserrat',
        color: theme.palette.text.tertiary,
    },
    textQuestion: {
        fontSize: 14,
        fontWeight: 700,
        textAlign: 'left',
        fontFamily: 'Montserrat',
        color: theme.palette.text.tertiary,
    },
    buttonsStep: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: theme.spacing(2),
        '& .MuiBox-root': {
            width: 139,
            height: 34,
        },
        '@media (max-width: 699px)': {
            justifyContent: 'center',
        },
    },
    btn: {
        border: 'none',
        width: 139,
        height: 34,
        borderRadius: 33,
        fontSize: 16,
        fontWeight: 'bold',
        cursor: 'pointer',
        backgroundSize: '200%',
        transition: '0.4s',
        '&:hover': {
            backgroundPosition: 'right',
        },
    },
    btnUpdateNow: {
        color: '#fff',
        textTransform: 'initial',
        backgroundImage: `linear-gradient(90deg, #19B8DA, ${theme.palette.primary.main} 50%)`,
        '&:hover': {
            textDecoration: 'unset',
        },
    },
    btnNotNow: {
        color: theme.palette.secondary.button,
        border: `2px solid ${theme.palette.secondary.button}`,
        transition: linear,
        width: 120,
        height: 34,
        paddingLeft: 15,
        paddingRight: 15,
        '& svg': {
            '& path': {
                fill: theme.palette.secondary.button,
            },
        },
        '&:hover': {
            transition: linear,
            color: '#fff',
            backgroundColor: theme.palette.secondary.button,

            '& svg': {
                '& path': {
                    fill: '#fff',
                },
            },
        },
    },
}))

const LinearProgressStyle = {
    height: 5,
    width: '108%',
    marginLeft: -25,
    borderRadius: 8,
    '&.MuiLinearProgress-colorPrimary': {
        backgroundColor: '#D5D7DC',
    },
}

const BorderLinearProgress = styled(LinearProgress)(() => ({
    ...LinearProgressStyle,
    '& .MuiLinearProgress-bar': {
        borderRadius: 8,
        background: '#5785DC',
    },
}))

const RegistrationUpdateModal = (props) => {
    const { openModal, closeModal } = props

    const classes = useStyles()
    const history = useHistory()
    const registrationUpdateClient = useRegistrationUpdateClient()

    const { getUser } = useSecurity()

    const user = getUser()

    const [progress, setProgress] = useState(100)

    const updateRegistrationData = () => {
        history.push('/atualizacao-cadastral')
    }

    const helloUser = `Olá ${user?.name}`

    const registerDateOutdated = useCallback(() => {
        registrationUpdateClient().registerDateOutdated(user?.email).then(() => {
            localStorage.setItem('RegistrationUpdate', 1)
            closeModal(false)
        }, () => {
            localStorage.setItem('RegistrationUpdate', 1)
            closeModal(false)
        })
    }, [closeModal, user, registrationUpdateClient])

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress - 1.66))
            if (progress <= 0) {
                registerDateOutdated()
            }
        }, 250)
        return () => {
            clearInterval(timer)
        }
    }, [progress, registerDateOutdated])

    return (
        <ModalInfo
            open={openModal}
            close={false}
            onClose={() => {
                closeModal(false)
                localStorage.setItem('RegistrationUpdate', 1)
            }}
            className={classes.modal}
        >
            <Box alignItems="center" justifyContent="center" ml={1}>
                <Box mb={1}>
                    <Typography className={classes.helloUser}>{helloUser}</Typography>
                </Box>
                <Box mb={2}>
                    <Typography className={classes.textTitle}>
                        Percebemos que seus dados cadastrais estão desatualizados.
                    </Typography>
                </Box>
                <Box mb={4}>
                    <Typography className={classes.textQuestion}>
                        Vamos confirmar seus dados?
                    </Typography>
                </Box>
                <Box justifyContent="flex-start" className={classes.buttonsStep}>
                    <Box>
                        <Button
                            className={clsx(classes.btn, classes.btnUpdateNow)}
                            onClick={updateRegistrationData}
                        >
                            <Typography style={{
                                fontSize: 14,
                                fontWeight: 700,
                            }}
                            >
                                Atualizar agora
                            </Typography>
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            className={classes.btnNotNow}
                            onClick={() => registerDateOutdated()}
                        >
                            <Typography style={{
                                fontSize: 14,
                                fontWeight: 700,
                            }}
                            >
                                Agora não
                            </Typography>
                        </Button>
                    </Box>
                </Box>
                <Box mt={1.7} width="100%">
                    <BorderLinearProgress
                        variant="determinate"
                        value={progress}
                        sx={{
                            '& .MuiLinearProgress-colorPrimary': {
                                backgroundColor: '#D5D7DC',
                            },
                            '& .MuiLinearProgress-barColorPrimary': {
                                backgroundColor: '#5785DC',
                            },
                        }}

                    />
                </Box>
            </Box>
        </ModalInfo>
    )
}

RegistrationUpdateModal.propTypes = {
    openModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
}

export default RegistrationUpdateModal
